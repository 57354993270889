






























































































import {vxm} from "@/store";
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import BorderlessCard from "@/views/viewcomponents/BorderlessCard.vue";
import CaseManagementCard from "@/views/viewcomponents/CaseManagementCard.vue";


import axios from "axios";
import firebase from "firebase";
import {CallConnectDTO, CaseApiFactory, Configuration} from "../../../../shared_vue/openapi/caseapi";
import {CaseDTO} from "@shared_vue/openapi/caseapi/api";
import {FileApiFactory} from "../../../../shared_vue/openapi/myvaultapi";


@Component({
  components: { WaitModal, CaseManagementCard, BorderlessCard}
})

//the layout for each asset page is the same
//only the content and items differ
//so I think it will also be good to lazy-fetch these instead of cacheing so much data all the time
export default class CaseManagement extends Vue {


  private title: string = "Title";
  
  private showCallPopup: boolean = false;
  private showConnecting: boolean = false;
  private callMessage: string = "Connecting...";
  private blurb: string = "";
  private showWait: boolean = false;
  private waitABit = false;
  private showNew: boolean = false;
  @Prop(String) readonly caseid: string|undefined;
  // private description: any={};
  private ui = vxm.ui;
  private auth = vxm.auth;
  
  
  mounted(){
    if (!this.auth.idToken) {
      this.waitABit = true;
      return;
    }
  }
  
  

  get displayName(){
    return this.auth.usr?.displayName;
  }
  
  private showMessage(text: string) {
    //@ts-ignore
    document.getElementById("message").textContent = text;
  }
  
}
