










































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {CaseApiFactory, CaseDTO, CaseStatus} from "@shared_vue/openapi/caseapi/api";
import {Configuration} from "../../../../shared_vue/openapi/caseapi";


@Component
export default class CaseManagementCard extends Vue {
  @Prop(String) readonly src: string | undefined;
  @Prop({default: 'true'}) readonly showButton : boolean | undefined;
  @Prop(String) readonly title1: string | undefined
  @Prop(String) readonly title2: string | undefined
  @Prop(String) readonly description: string | undefined
  @Prop(String) readonly btnText: string | undefined;
  @Prop(String) readonly caseId: string | undefined;
  @Prop() readonly buttonFunction: Function | undefined;
  @Prop({ type: String }) navlink: string | undefined
  private ui = vxm.ui;
  //note these dynamic static assets will likely be served from S3

  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private auth = vxm.auth;
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL);

  private navigate(){
    if (this.caseId){
      //set to "open"
      let caseInstance: CaseDTO = {
        id: parseInt(this.caseId),
        status: CaseStatus.Open,
        officerId : this.auth.UserId
      };
      this.caseApi.caseUpdateCase(caseInstance);
    }
    if (this.navlink)
      this.$router.push(this.navlink);
    else if (this.buttonFunction)
      this.buttonFunction(this);
  }


}
